import { graphql } from 'gatsby';
import { SimpleProductHero } from '../components/product/SimpleProductHero';
import { PageWrapper } from '../components/layout/PageWrapper';
import { SectionNutrition } from '../components/product/SectionNutritionTable';

const SingleProductTemplate = ({
  data: {
    datoCmsProdotti: {
      seo,
      mainImage: { gatsbyImageData },
      titolo,
      multilineDescription,
      productCategory: { categoryTitle, categorySlug, categoryRecordId },
      valoriNutrizionali,
      limitedEdition,
    },
  },
  pageContext,
}) => {
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <SimpleProductHero
        categoryRecordId={categoryRecordId}
        limitedEdition={limitedEdition}
        categoryTitle={categoryTitle}
        categoryLink={categorySlug}
        imageData={gatsbyImageData}
        productTitle={titolo}
        productDescription={multilineDescription}
      />
      <SectionNutrition nestedArray={valoriNutrizionali} />
    </PageWrapper>
);
};

export default SingleProductTemplate;

export const query = graphql`
  query ProductQuery($id: String!, $locale: String!) {
    datoCmsProdotti(originalId: { eq: $id }, locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      originalId
      titolo
      multilineDescription
      slug
      productCategory {
        categoryRecordId: originalId
        categoryTitle: title
        categorySlug: slug
      }
      limitedEdition
      mainImage {
        gatsbyImageData(placeholder: NONE, aspectRatio: 1)
      }
      valoriNutrizionali {
        originalId
        tipo
        informationNode {
          childMarkdownRemark {
            html
          }
        }
        quantitValoriMedi
        valoriNutrizionaliNested {
          value
          name
          originalId
        }
      }
    }
  }
`;
