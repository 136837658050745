import { Fragment } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: center;
  row-gap: var(--gap-5);
`;

const TableHeading = styled.h1`
  font-weight: 700;
  font-size: var(--font-size);
  color: var(--blue-2);

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const TableContent = styled.p`
  font-weight: 700;
  font-size: var(--font-size);
  color: var(--gold-2);

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const NutritionTable = ({ nutritionData }) => {
  const intl = useIntl();
  return (
    <Table>
      <TableHeading>
        {intl.formatMessage({ id: 'nutritionalValues' })}
      </TableHeading>
      <TableHeading>per 100 g</TableHeading>
      {nutritionData.map(({ name, value, originalId }) => (
        <Fragment key={originalId}>
          <TableContent>{name}</TableContent>
          <TableContent>{value}</TableContent>
        </Fragment>
      ))}
    </Table>
  );
};
