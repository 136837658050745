import { useState } from 'react';
import styled, { css } from 'styled-components';

import { HeadingSection, RegularParagraph } from '../styles/Typography';
import { NutritionsIcon } from '../vectors/Drawings';
import { NutritionTable } from './SectionNutritionInfo';
import { useIntl } from 'react-intl';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gold-3);
  padding: var(--gap-2) var(--padding) var(--wave-offset-padding) var(--padding);
  margin-bottom: ${({ isHeroProduct }) =>
    !isHeroProduct && 'var(--wave-offset-negative-margin)'};

  ${({ isHeroProduct }) =>
    isHeroProduct &&
    css`
      @media screen and (max-width: 680px) {
        padding: 200px var(--padding) var(--wave-offset-padding) var(--padding);
        margin-top: -160px;
      }
    `}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: var(--layout-desktop-container);
`;

const MainInfoSection = styled.section`
  display: grid;
  row-gap: var(--gap-3);
`;

const SelectWrapper = styled.div`
  display: grid;
  row-gap: var(--gap-7);
  justify-items: center;
`;

const SelectLabel = styled.span`
  font-size: var(--font-size);
  color: var(--blue-2);
  font-weight: 700;
`;

const Select = styled.select`
  padding: 0.6em 1.8em 0.6em 0.4em;
  border-radius: 5px;
  max-width: 100%;
  border: 2px solid #d0d0d0;
  background: var(--background-white);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 0.45em top 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  color: var(--blue-2);
  font-weight: 500;
  font-size: var(--font-size-micro-1);
`;

const IngredientsSection = styled.section`
  display: grid;
  row-gap: var(--gap-6);
  justify-items: center;
`;

const NutritionTableSection = styled.section`
  display: grid;
  row-gap: var(--gap-3);
  justify-items: center;

  & svg {
    width: 80px;
    height: 80px;
  }
`;

export const SectionNutrition = ({ nestedArray, isHeroProduct }) => {
  const [defaultArray] = nestedArray; // Initial nested array
  const [selectedNested, setSelectedNested] = useState(defaultArray);

  const handleSelect = (e) => {
    const [selectedArray] = nestedArray.filter(
      ({ originalId }) => originalId === e.target[e.target.selectedIndex].id
    );
    setSelectedNested(selectedArray);
  };

  if (!defaultArray) return null;

  const intl = useIntl();

  return (
    <Wrapper isHeroProduct={isHeroProduct}>
      <Container>
        <MainInfoSection>
          <SelectWrapper>
            {nestedArray.length === 1 ? (
              <>
                <HeadingSection extraSmall small blueHeading>
                  {intl.formatMessage({ id: 'product' })}
                </HeadingSection>
                <RegularParagraph
                  css={{ textAlign: 'center', maxWidth: 450 }}
                  as="div"
                >
                  {nestedArray[0].tipo}
                </RegularParagraph>
              </>
            ) : (
              <>
                <SelectLabel>{intl.formatMessage({ id: 'product' })}</SelectLabel>
                <Select onChange={handleSelect}>
                  {nestedArray.map(({ originalId, value, tipo }) => (
                    <option
                      key={originalId}
                      id={originalId}
                      value={value}
                      label={tipo}
                    />
                  ))}
                </Select>
              </>
            )}
          </SelectWrapper>
          <IngredientsSection>
            <HeadingSection extraSmall small blueHeading>
              {intl.formatMessage({ id: 'ingredients' })}
            </HeadingSection>
            <RegularParagraph
              css={{ textAlign: 'center', maxWidth: 450 }}
              as="div"
              dangerouslySetInnerHTML={{
                __html: selectedNested.informationNode.childMarkdownRemark.html,
              }}
            />
          </IngredientsSection>
          <NutritionTableSection>
            <IngredientsSection as="div">
              <NutritionsIcon />
              <HeadingSection
                extraSmall
                blueHeading
                css={{ textAlign: 'center' }}
              >
                {intl.formatMessage({ id: 'nutritionDeclaration' })}
              </HeadingSection>
              <RegularParagraph css={{ textAlign: 'center' }}>
                {selectedNested.quantitValoriMedi}
              </RegularParagraph>
            </IngredientsSection>
            <NutritionTable
              nutritionData={selectedNested.valoriNutrizionaliNested}
            />
          </NutritionTableSection>
        </MainInfoSection>
      </Container>
    </Wrapper>
  );
};
