import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';
import { HeadingSection, SubtitleSection } from '../styles/Typography';
import { FacebookIcon } from '../vectors/Social';
import { Breadcrumb } from './BreadCrumb';
import { LimitedEdition } from '../misc/LimitedEdition';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: var(--padding);
`;

const Container = styled.div`
  width: 1280px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--gap-3);

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Left = styled.div`
  padding: var(--gap-2);

  @media screen and (max-width: 810px) {
    padding: var(--gap-5);
  }
`;

const Right = styled.div`
  align-content: center;
  display: grid;
  row-gap: var(--gap-5);
`;

const ShareSpan = styled.span`
  font-size: var(--font-size);
  color: var(--gold-2);
  white-space: nowrap;
  font-weight: 500;
`;

const SocialIcons = styled.div`
  display: grid;
  margin-top: var(--gap-5);
  align-items: center;
  column-gap: var(--gap-5);
  grid-auto-flow: column;
  width: min-content;

  & svg {
    width: 30px;
    height: 30px;
    & path {
      fill: var(--gold-2);
    }
  }
`;

export const SimpleProductHero = ({
  imageData,
  productTitle,
  productLink,
  productDescription,
  categoryTitle,
  categoryRecordId,
  categoryLink,
  limitedEdition,
}) => {
  const { pathname } = useLocation();
  const intl = useIntl();

  return (
    <>
      <Breadcrumb
        categoryRecordId={categoryRecordId}
        categoryTitle={categoryTitle}
        categoryLink={categoryLink}
        productTitle={productTitle}
        productLink={productLink}
      />
      <Wrapper>
        <Container>
          <Left>
            <GatsbyImage image={imageData} alt="" />
          </Left>
          <Right>
            {limitedEdition && (
              <LimitedEdition
                content={intl.formatMessage({ id: 'limitedEdition' })}
              />
            )}
            <HeadingSection goldHeading>{productTitle}</HeadingSection>
            <SubtitleSection>{productDescription}</SubtitleSection>
            <SocialIcons>
              <ShareSpan>{intl.formatMessage({ id: 'share' })}</ShareSpan>
              <a
                rel="noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=https://larosadeigusti.it${pathname}`}
              >
                <FacebookIcon />
              </a>
            </SocialIcons>
          </Right>
        </Container>
      </Wrapper>
    </>
  );
};
