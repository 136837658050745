import { Link } from 'gatsby';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { usePageLocale } from '../../hooks/usePageLocale';
import { Navigator } from '../LanguageHelpers/Navigator';
import { useDefaultLocale } from '../../hooks/useDefaultLocale';
import { useProductsSlug } from '../../hooks/useProductsSlug';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: var(--padding);
  background: ${({ hasPaperBackground }) =>
    hasPaperBackground === false &&
    'url(https://www.datocms-assets.com/56612/1641694573-paper-pattern.jpg)'};
`;

const Container = styled.div`
  width: 1280px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--gap-3);

  @media screen and (max-width: 1240px) {
    width: 100%;
  }

  @media screen and (max-width: 610px) {
    grid-template-columns: 1fr;
  }

  & #current_product_mobile {
    @media screen and (max-width: 610px) {
      color: var(--gold-2);
      font-weight: 700;
    }

    @media screen and (min-width: 611px) {
      display: none;
    }
  }
`;

const Nav = styled.nav`
  display: inline-grid;
  width: min-content;
  grid-auto-flow: column;

  & a {
    white-space: nowrap;
    color: var(--blue-2);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & span:not(.currentProduct) {
    padding: 0 var(--gap-6);
    color: var(--blue-2);
  }
`;

const CurrentProduct = styled.span`
  color: var(--gold-2);
  font-weight: 700;
  white-space: nowrap;

  @media screen and (max-width: 610px) {
    display: none;
  }
`;

export const Breadcrumb = ({
  categoryTitle,
  productTitle,
  categoryRecordId,
  hasPaperBackground,
}) => {
  const { locale } = usePageLocale();
  const { defaultLocale } = useDefaultLocale();
  const { slug } = useProductsSlug(locale);
  const intl = useIntl();

  return (
    <Wrapper hasPaperBackground={hasPaperBackground}>
      <Container>
        <Nav>
          <Link to={locale === defaultLocale ? `/${slug}` : `/${locale}/${slug}`}>
            {intl.formatMessage({ id: 'products' })}
          </Link>
          <span>{' / '}</span>
          <Navigator recordId={categoryRecordId}>{categoryTitle}</Navigator>
          <span>{' / '}</span>
          <CurrentProduct className="currentProduct">
            {productTitle}
          </CurrentProduct>
        </Nav>
        <span id="current_product_mobile">{productTitle}</span>
      </Container>
    </Wrapper>
  );
};
