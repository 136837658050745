import styled from 'styled-components';

const Tag = styled.h2`
  color: var(--gold-2);
  background: var(--gold-3);
  padding: 0.2em 0.6em;
  white-space: nowrap;
  width: min-content;
  font-size: var(--font-size);
  border: 2px solid var(--gold-2);
`;

export const LimitedEdition = ({ content }) => <Tag>{content}</Tag>;
